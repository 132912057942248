// import _ from "lodash";
import {GET_CURRENT_USER, GET_BOOKMARKED_ITEMS, GET_ACCOUNT_INVOICE, GET_ACCOUNT_INVOICE_PAYMENTS, UPDATE_USER_PROFILE, UPDATE_CONTACT_IMAGE} from "store/actions/actionTypes";

const currentUserReducer = (state = {}, action) => {
  let currentUser = null
  if (action.type === GET_CURRENT_USER){
    if (action.payload !== undefined){
      currentUser = action.payload
    }
    return {
      ...state, ...currentUser
    }
  }
  else if (action.type === GET_BOOKMARKED_ITEMS) {
    if(action.payload){
      currentUser = action.payload
    }
    return{
      ...state,
      ...currentUser
    }
  }
  else if(action.type === GET_ACCOUNT_INVOICE){
    let invoiceData = null;
    if(action.payload){
      invoiceData = action.payload;
    }
    let currentUser = {...state.currentUser, ...invoiceData};
    return {
      ...state, ...currentUser
    }
  }
  else if (action.type === GET_ACCOUNT_INVOICE_PAYMENTS){
    let invoicePayments = null;
    if(action.payload){
      invoicePayments = action.payload;
    }
    let currentUser = {...state, ...invoicePayments};
    return {...state, ...currentUser};
  }
  else if (action.type === UPDATE_USER_PROFILE){
    let updatedUser = null;
    if(action.payload){
      updatedUser = action.payload;
    }
    let currentUser = {...state, ...updatedUser};
    return {...state, ...currentUser};
  }
  else if (action.type === UPDATE_CONTACT_IMAGE) {
    return {
      ...state,
      exhibitorAccount: {
        ...state.exhibitorAccount,
        contacts: state.exhibitorAccount.contacts.map(contact =>
          contact._id === action.payload.contactId
            ? { ...contact, contactImage: action.payload.imageUrl }
            : contact
        )
      }
    };
  }
  else return state;
}

export default currentUserReducer;