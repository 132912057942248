const {
  FETCH_ACCOUNT_CONTACTS,
  FETCH_ACCOUNT_CONTACTS_REQUEST,
  UPDATE_CONTACT
} = require("store/actions/actionTypes");

const initialState = {
  contacts: [],
  booths: [],
  dataFetched: false,
};

const contactsReducer = (state = initialState, action) => {
  if (action.type === FETCH_ACCOUNT_CONTACTS_REQUEST) {
    return {
      ...state,
      dataFetched: false,
    };
  }
  else if (action.type === FETCH_ACCOUNT_CONTACTS) {
    return {
      ...state,
      contacts: action.contacts || state.contacts,
      booths: action.booths || state.booths,
      error: action.error || null,
      dataFetched: true,
    };
  }
  else if (action.type === UPDATE_CONTACT) {
    const contactExists = state.contacts.some(contact => contact._id === action.contactId);
    return {
      ...state,
      contacts: contactExists
        ? state.contacts.map(contact =>
          contact._id === action.contactId
            ? {...contact, ...action.updatedData} // Update the existing contact
            : contact
        )
        : [...state.contacts, {_id: action.contactId, ...action.updatedData}] // Add new contact if not found
    };
  }  
  return state;
};

export default contactsReducer;
