import "bootstrap/dist/css/bootstrap.min.css";
import React, {useState, useEffect, lazy, Suspense} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {connect, useSelector} from "react-redux";

import {fetchCurrentShow, fetchCurrentUser} from "store/actions";
import {getUserFromCookie, getShowAbbreviation} from "common";
import {
  // SHOW_RECORD_TYPES,
  hasSubmittedTheFormForShow,
  validateApprovedExhibitorUser,
  validateCurrentUser,
  validateExhibitorUser,
} from "components/utils";

import useDocumentTitle from "./useDocumentTitle";

import "styles/App.scss";
import NavMenu from "components/NavMenu/NavMenu";
import Footer from "components/static/Footer";
import Loader from "components/Loader";
import LandingPageWrapper from "components/landingPage/Wrapper";
import Logout from 'components/Logout';
import {FETCH_SELECTED_THEME} from "store/actions/actionTypes";
import Profile from "./Profile";
import {EXHIBITOR} from "constants";
// import {useIsConferenceShow} from "hooks/index";

const FloorPlanWrapper = lazy(() => import('components/FloorPlanWrapper/FloorPlanWrapper'));
const UnpublishedShow = lazy(() => import('components/FloorPlanWrapper/UnpublishedShow'));
const ExhibitorList = lazy(() => import('components/ExhibitorsList'));
const Sessions = lazy(() => import('components/Sessions'));
const SessionDetail = lazy(() => import('components/Sessions/SessionDetail'));
const SpeakerDetails = lazy(() => import('components/Sessions/SpeakerDetails'));
const Sponsors = lazy(() => import('components/Sponsors'));
const AttendeeList = lazy(() => import('components/AttendeeList'));

const Login = lazy(() => import('components/Login/AuthContainer'));

// const MyShow = lazy(() => import('components/MyShow'));
const Dashboard = lazy(() => import('components/exhibitorPortal/Dashboard/Dashboard'));
const ExhibitorHome = lazy(() => import('components/exhibitorPortal/ExhibitorHome'));
const ExhibitorRegistrationForm = lazy(() => import('components/exhibitorPortal/ExhibitorRegistrationForm'));
const Invoice = lazy(() => import('components/exhibitorPortal/Invoice/InvoiceWrapper'));
const Registration = lazy(() => import('components/exhibitorPortal/AttendeeRegistration'));
const Payments = lazy(() => import('components/exhibitorPortal/Payments')); 
// const Exhibitors = lazy(() => import('components/exhibitorPortal/Exhibitors'));
const ProductsWrapper = lazy(() => import('components/exhibitorPortal/Products/ProductsWrapper'));
const Attendees = lazy(() => import('components/exhibitorPortal/Attendees'));

const PageNotFound = lazy(() => import('components/static/PageNotFound'));
const InternalServerError = lazy(() => import('components/static/InternalServerError'));
const PrivacyPolicy = lazy(() => import('components/static/PrivacyPolicy/PrivacyPolicy'));

const Chat = lazy(() => import('components/Chat'));

const {REACT_APP_DEFAULT_THEME} = process.env;

function App(props) {
  const [showId, setShowId] = useState(undefined);
  const [showName, setShowName] = useState(undefined);
  const [orgId, setOrgId] = useState(undefined);
  const [showAbbr, setShowAbbr] = useState(getShowAbbreviation() || null);
  const [currentUser, setCurrentUser] = useState({});

  // const isConferenceShow = useIsConferenceShow(); // hook to check if a show is a conference show.
  
  const showDefaultTheme = useSelector(state => state.show?.defaultTheme);
  const selectedTheme = useSelector(state => state.selectedTheme?.selectedTheme);
  
  const isFloorplanEnabled = useSelector(state => state.show?.isFloorplanEnabled || false); 
  const isConferenceEnabled = useSelector(state => state.show?.isConferenceEnabled || false); 
  const isAttendeeRegistrationEnabled = useSelector(state => state.show?.isAttendeeRegistrationEnabled || false); 
  const isMultipleRegistrationsEnabled = useSelector(state => state.show?.isMultipleRegistrationsEnabled) || false;

  useDocumentTitle(showName || null, true);

  useEffect(() => {
    if (showAbbr) {
      props.fetchCurrentShow();
    } else {
      setShowId(null);
    }

    // fetch user details if the jwt token is present in the cookie.
    if (getUserFromCookie()) {
      props.fetchCurrentUser();
    }
    // else set the user id in redux user state to null.
    else {
      props.fetchCurrentUser(null);
    }
  }, []);

  useEffect(() => {
    if (getShowAbbreviation() && showAbbr !== getShowAbbreviation()) {
      setShowAbbr(getShowAbbreviation()?.toLocaleLowerCase());
    }

    if (currentUser !== props.currentUser) {
      setCurrentUser(props.currentUser);
    }

    // update the state if the redux store value changes.
    if (props.show._id !== showId) {
      setShowId(props.show?._id || null);
      setShowName(props.show.showName);
      setOrgId(props.show.orgId);
    }
  });

  // theme 
  useEffect(() => {
    // set the theme in redux store only after the show data is loaded
    // if a show has default theme and the user access the show without selecting a theme manually
    // then the show's default theme will be set.
    if(showId){
      const theme = selectedTheme || localStorage.getItem('selectedTheme') || showDefaultTheme || REACT_APP_DEFAULT_THEME;      
    // set selected theme in redux store. Floorplan's color can be decided based on this.
    props.fetchSelectedTheme(theme);
    }

  }, [showDefaultTheme, selectedTheme, showId])

  const renderFloorplan = () => { 
    if (isFloorplanEnabled) {
      return (
        <Suspense fallback={<Loader />}>
          <FloorPlanWrapper showId={showId} />
        </Suspense>
      );
    } 
    else {
      return <Navigate to={`/${showAbbr}`} />;
    }
    // else if (!isFloorplanEnabled) {
    //   if (isConferenceEnabled) { 
    //     return <Navigate to={`/${showAbbr}/sessions`} />;
    //   } else {
    //     return <Navigate to={`/${showAbbr}/account`} />; 
    //   }
    // }
    // return null;
  };  
  
  const renderAttendees = (user) => {
    if (isFloorplanEnabled || isAttendeeRegistrationEnabled) {
      return renderApprovedExhibitorComponent(
        <Suspense fallback={<Loader />}>
          <Attendees />
        </Suspense>,
        user
      );
    } 
    else if (!isFloorplanEnabled && !isAttendeeRegistrationEnabled) {
      return <Navigate to={`/${showAbbr}`} />; 
    }
    return null;
  };

  // Render the home/ exhibitor login.
  const renderExhibitorLoginComponent = (user) => {
    // Render the home if exhibitor user is logged in
    if (validateExhibitorUser(user) || validateCurrentUser(user)) {
      // return <Navigate to={`/${showAbbr}/home`} />; 
      return <Navigate to={`/${showAbbr}`} />;
    }
    // render the exhibitor login page
    return (
      <Suspense fallback={<Loader />}>
        <Login loginAs="Exhibitor" exhibitorId={user._id} />
      </Suspense>
    );
  };

  /**
   * @param {jsx} component `jsx` that will be rendered when the user is approved exhibitor user.
   * @param {object} user user object.
   * @returns `jsx` components based on the user.
   */
  // render exhibitor component, login page, or dashboard based on the user.
  const renderApprovedExhibitorComponent = (component, user) => {
    // user not logged in.
    if (user._id === null) {
      return (
        <Suspense fallback={<Loader />}>
          <Login loginAs="Exhibitor" exhibitorId={user._id} />
        </Suspense>
      );
    }

    // user logged in.
    else if (user._id) {
      // approved exhibitor.
      if (validateApprovedExhibitorUser(user, showId)) {
        return component;
      }
      // // exhibitor.
      // else if (validateExhibitorUser(user)) {
      //   return <Navigate to={`/${showAbbr}/home`} />;
      // }
      // // attendee
      else return <Navigate to={`/${showAbbr}`} />;
    }

    return <Loader />;
  }; //renderApprovedExhibitorComponent end

  /**
   * @param {jsx} component `jsx` that will be rendered when the user is an exhibitor user.
   * @param {object} user user object.
   * @returns `jsx` components based on the user.
   */
  // render the component passed as the first param or login page based on the user.
  const renderExhibitorComponent = (component) => {
    let user = currentUser;

    // user not logged in.
    if (user._id === null) {
      return <Navigate to={`/${showAbbr}/login`} />;
    }

    // logged in user.
    else if (user._id) {
      if (validateExhibitorUser(user)) {
        return component;
      }

      return <Navigate to={`/${showAbbr}`} />; //attendee
    }

    return <Loader />;
  }; // renderExhibitorComponent end

  const renderRegistrationForm = () => {
    const user = getUserFromCookie();

    // return loader when user is not fetched
    if (user && Object.keys(user).length === 0) {
      return <Loader />
    }

    const isApprovedExhibitor = validateApprovedExhibitorUser(user, showId);
    const isExhibitorWithSubmittedForm = validateExhibitorUser(user) && hasSubmittedTheFormForShow(user, showId);

    // if approved or has submitted the participation form
    if (isApprovedExhibitor || isExhibitorWithSubmittedForm) {
      return <Navigate to={`/${showAbbr}`} />;
    }

    // return exhibitor registration form. For logged-out user as well.
    return (
      <Suspense fallback={<Loader />}>
        <ExhibitorRegistrationForm
          show={props.show}
          showCloseModalBtn={true}
          user={user}
        />
      </Suspense>
    )
  }

  const renderPageNotFound = () => {
    return (
      <Suspense fallback={<Loader />}>
        <PageNotFound />
      </Suspense>
    );
  }

  /* if multiple registration is enabled then check for approved user 
    otherwise render the the component without checking any user details 
    (user should see without login for single registration page)
  */
  const renderAttendeeRegistrationComponent = () => {
    if (isMultipleRegistrationsEnabled) {
      return renderApprovedExhibitorComponent(
        <Suspense fallback={<Loader />}>
          <Registration />
        </Suspense>,
        currentUser
      );
    } else {
      return (
        <Suspense fallback={<Loader />}>
          <Registration />
        </Suspense>
      );
    }
  }

  const {show} = props;

  if (props.isFetching && showId === undefined) {
    return <Loader />;
  }

  else if (props.internalServerError) {
    return (
      <Suspense fallback={<Loader />}>
        <InternalServerError />
      </Suspense>
    );
  }

  else {
    return (
      <>
        {/* <div id="googlePageTranslateContainer" /> */}
        <div className={`rootWrapperContainer theme-${selectedTheme}`}>
        <Router>
          <div className={`main-wrapper theme-${selectedTheme}`}>
            {/* <div className="navmenu-wrapper"> */}
              {showId && (
                <NavMenu show={props.show} user={currentUser} />
              )}
            {/* </div> */}
            <div className="body-content-wrapper container-fluid">
              {/* Conditionally render UnpublishedShow independent of route */}
              {props.show.showUnpublished && (
                <Suspense fallback={<Loader />}>
                  <UnpublishedShow />
                </Suspense>
              )}

              <Routes>
                {/**
                * TODO: need to change this using path showAbbr.
                * Change this and use dynamic url as passing parameter. Like: /:showAbbr
                */}
                {showId && (
                  <>
                      {/* redirect showAbbr to /sessions if show type is conference */}
                      <Route
                        path={`${showAbbr}/floorplan`}
                        exact
                        element={
                          renderFloorplan()
                        }
                      />

                    {/* Disable these routes if show is not published */}
                    {!props.show.showUnpublished && (
                      <>
                        {(isFloorplanEnabled) && (
                          <Route
                            path={`${showAbbr}/exhibitors`}
                            exact
                            element={
                              <Suspense fallback={<Loader />}>
                                <ExhibitorList />
                              </Suspense>
                            }
                          />
                        )}
                        <Route
                          path={`${showAbbr}/sessions`}
                          exact
                          element={
                            <Suspense fallback={<Loader />}>
                              <Sessions orgId={orgId} showId={showId} showAbbr={showAbbr} />
                            </Suspense>
                          }
                        />
                        <Route
                          path={`${showAbbr}/sessions/:sessionId`}
                          exact
                          element={
                            <Suspense fallback={<Loader />}>
                              <SessionDetail orgId={orgId} showId={showId}/>
                            </Suspense>
                          }
                        />
                        <Route
                          path={`${showAbbr}/speaker/:speakerId`}
                          exact
                          element={
                            <Suspense fallback={<Loader />}>
                              <SpeakerDetails orgId={orgId} showId={showId} showAbbreviation={showAbbr} />
                            </Suspense>
                          }
                        />
                        <Route
                          path={`${showAbbr}/sponsors`}
                          exact
                          element={
                            <Suspense fallback={<Loader />}>
                              <Sponsors />
                            </Suspense>
                          }
                        />
                        <Route
                          path={`${showAbbr}/sponsors/:sponsorId`}
                          exact
                          element={
                            <div>Sponsor Details</div>
                          }
                        />
                        <Route
                          path={`${showAbbr}/attendees`}
                          exact
                          element={
                            <Suspense fallback={<Loader />}>
                              <AttendeeList /> 
                            </Suspense>
                          } 
                        /> 
                        {/* <Route
                          path={`/${showAbbr}/login`}
                          exact
                          element={
                            validateCurrentUser(currentUser) ? (
                              <Navigate to={`/${showAbbr}`} />
                            ) : (
                              <Suspense fallback={<Loader />}>
                                <Login loginAs="User" />
                              </Suspense>
                            )
                          }
                        /> */}

                        <Route
                          path={`/${showAbbr}/profile`}
                          exact
                          element={
                            validateCurrentUser(currentUser) ? (
                              <Suspense fallback={<Loader />}>
                                <Profile />
                              </Suspense>
                            ) : (
                              <Navigate to={`/${showAbbr}/login`} />
                            )
                          }
                        />

                        <Route
                          path={`/${showAbbr}/chat`}
                          exact
                          // element={renderApprovedExhibitorComponent(
                          element={renderExhibitorComponent(
                            <Chat />,
                            currentUser
                          )}
                        />

                        {/* <Route
                          path={`/${showAbbr}/myshow`}
                          exact
                          element={
                            <Suspense fallback={<Loader />}>
                              <MyShow
                                showId={showId}
                                userId={currentUser._id}
                                orgId={orgId}
                              />
                            </Suspense>
                          }
                        /> */}
                      </>
                    )}

                    <Route
                      path={`${showAbbr}/privacy-policy`}
                      exact
                      element={
                        <Suspense fallback={<Loader />}>
                          <PrivacyPolicy />
                        </Suspense>
                      }
                    />

                    <Route
                      path={`/${showAbbr}/logout`}
                      exact
                      element={
                        <Logout />
                      }
                    />

                    {/* exhibitor portal routes */}
                    <Route
                      path={`/${showAbbr}/login`}
                      exact
                      element={renderExhibitorLoginComponent(currentUser)}
                    />
                    <Route
                        path={`/${showAbbr}/participate`}
                      exact
                      // use the user from cookie but only for exhibitor registration component.
                      element={renderRegistrationForm()}
                    />

                    {!props.show.showUnpublished && (
                      <>
                      {  
                        <Route
                          path={`/${showAbbr}/attendee-management`}
                          exact
                          element={  
                            <Suspense fallback={<Loader />}>
                              {renderAttendees(currentUser)}
                            </Suspense>
                          }
                        /> 
                      }
                        <Route
                          path={`/${showAbbr}`}
                          exact
                          element={
                            // renderExhibitorComponent(
                              <Suspense fallback={<Loader />}>
                                <ExhibitorHome
                                  currentShow={props.show}
                                  user={currentUser}
                                />
                              </Suspense>
                            // )
                          }
                        />
                        <Route
                          path={`/${showAbbr}/dashboard`}
                          exact
                          element={
                            renderExhibitorComponent(
                              <Suspense fallback={<Loader />}>
                                <Dashboard
                                  currentShow={props.show}
                                  user={currentUser}
                                />
                              </Suspense>
                            )
                          }
                        />

                        {/* These routes should be disabled if the 'Show' is not available for exhibitor portal. */}
                        {props.show.availableOnExhibitorPortal && (
                          <>
                            {/* <Route
                              path={`/${showAbbr}/products`}
                              exact
                              element={
                                renderApprovedExhibitorComponent(
                                  <Suspense fallback={<Loader />}>
                                    <ProductsWrapper />
                                  </Suspense>,
                                  currentUser
                                )
                              }
                            />  */}

                            <Route
                              path={`/${showAbbr}/invoice`}
                              exact
                              element={
                                renderApprovedExhibitorComponent(
                                  <Suspense fallback={<Loader />}>
                                    < Invoice
                                      currentShow={props.show}
                                      user={currentUser}
                                    />
                                  </Suspense>,
                                  currentUser
                                )
                              }
                            />
                            <Route
                              path={`/${showAbbr}/registration`}
                              exact
                              element={
                                renderAttendeeRegistrationComponent()
                              }
                            />
                            <Route
                              path={`/${showAbbr}/payments`}
                              exact
                              element={
                                renderApprovedExhibitorComponent(
                                  <Suspense fallback={<Loader />}>
                                    <Payments
                                      currentShow={props.show}
                                      user={currentUser}
                                    />
                                  </Suspense>,
                                  currentUser
                                )
                              }
                            />
                            {/* <Route
                              path={`${showAbbr}/update/exhibitors`}
                              exact
                              element={
                                renderExhibitorComponent(
                                  <Suspense fallback={<Loader />}>
                                    <Exhibitors />
                                  </Suspense>
                                )
                              }
                            /> */}
                          </>
                        )}
                      </>
                    )}

                    <Route
                      path={`${showAbbr}/*`}
                      element={
                        <Navigate to={`/${showAbbr}`} />
                      }
                    />
                  </>
                )}

                <Route
                  path={`privacy-policy`}
                  exact
                  element={
                    <Suspense fallback={<Loader />}>
                      <PrivacyPolicy />
                    </Suspense>
                  }
                />

                <Route path="/404" exact element={renderPageNotFound()} />

                <Route
                  path={`/`}
                  exact
                  element={<LandingPageWrapper />}
                />

                {/* unreachable route/404 route */}
                <Route path="*" exact element={renderPageNotFound()} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  let show = { _id: null };
  if (state.show) {
    show = state.show
  }

  return {
    show,
    currentUser: state.currentUser,
    internalServerError: state.internalServerError.status || false,
    pageNotFoundError: state.pageNotFound.status || false,
    isFetching: Object.keys(state.show || {}).length === 0,
  };
};

const fetchSelectedTheme = (theme) => async (dispatch) => {
  dispatch({
    type: FETCH_SELECTED_THEME,
    payload: theme
  });
}

export default connect(mapStateToProps, { fetchCurrentShow, fetchCurrentUser, fetchSelectedTheme })(
  App
);
