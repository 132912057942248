import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getShowAbbreviation } from "common/index";
import { validateApprovedExhibitorUser } from "components/utils";
import { showNavConfig } from "common/menuConfig";

const useSortedLinks = () => {
  const showAbbr = getShowAbbreviation();
  const user = useSelector((state) => state.currentUser);
  const showId = useSelector((state) => state.show?._id);
  const isFloorplanEnabled = useSelector((state) => state.show?.isFloorplanEnabled || false);
  const isAttendeeRegistrationEnabled = useSelector((state) => state.show?.isAttendeeRegistrationEnabled || false);
  const isConferenceEnabled = useSelector((state) => state.show?.isConferenceEnabled || false);

  // Function to determine if a link should be rendered based on its condition
  const shouldRenderLink = (condition) => {
    switch (condition) {
      case "Floorplan":
        return isFloorplanEnabled;
      case "AttendeeRegistration":
        return isAttendeeRegistrationEnabled;
      case "Conference":
        return isConferenceEnabled;
      case "ApprovedExhibitor":
        return validateApprovedExhibitorUser(user, showId);
      default:
        return true;
    }
  };

  const sortedLinks = useMemo(() => {
    const showConfig = showNavConfig[showAbbr] || showNavConfig.DEFAULT;

    // Sort and filter links based on the 'order' and 'condition' properties
    return showConfig.links
      .filter((link) => !link.condition || shouldRenderLink(link.condition))
      .sort((a, b) => a.order - b.order);
  }, [showAbbr, user, showId, isFloorplanEnabled, isAttendeeRegistrationEnabled, isConferenceEnabled]);

  return sortedLinks;
};

export default useSortedLinks;