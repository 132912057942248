import { getShowAbbreviation } from "common/index";
const showAbbr = getShowAbbreviation();

export const showNavConfig = {
  kcmafc24: {
    links: [
      { 
        order: 1, 
        label: "Home", 
        to: `/${showAbbr}` 
      },
      {
        order: 2, 
        label: "Floor Plan", 
        condition: "Floorplan", 
        to: `/${showAbbr}/floorplan`
      },
      {
        order: 3, 
        label: "Exhibitors", 
        condition: "Floorplan", 
        to: `/${showAbbr}/exhibitors`
      },
      {
        order: 4, 
        label: "Speakers", 
        external: true, 
        link: "https://kcma.org/event-microsite/speakers"
      },
      {
        order: 5, 
        label: "Tours", 
        external: true, 
        link: "https://kcma.org/event-microsite/2024-fall-conference-tours"
      },
      {
        order: 6, 
        label: "Hotel", 
        external: true, 
        link: "https://kcma.org/event-microsite/hotel"
      },
      {
        order: 7, 
        label: "Agenda", 
        condition: "Conference", 
        to: `/${showAbbr}/sessions`
      },
      {
        order: 8, 
        label: "Who's Coming", 
        condition: "AttendeeRegistration", 
        to: `/${showAbbr}/attendees`
      },
      {
        order: 9, 
        label: "Sponsors", 
        condition: "Conference", 
        to: `/${showAbbr}/sponsors`
      },
      {
        order: 10, 
        label: "Registration", 
        condition: "AttendeeRegistration", 
        to: `/${showAbbr}/registration`
      },
      {
        order: 11, 
        label: "My Account", 
        condition: "ApprovedExhibitor", 
        to: `${showAbbr}/invoice#Account`
      },
    ],
  },
  DEFAULT: {
    links: [
      { 
        order: 1, 
        label: "Home", 
        to: `/${showAbbr}` 
      },
      {
        order: 2, 
        label: "Floor Plan", 
        condition: "Floorplan", 
        to: `/${showAbbr}/floorplan`
      },
      {
        order: 3, 
        label: "Exhibitors", 
        condition: "Floorplan", 
        to: `/${showAbbr}/exhibitors`
      },
      {
        order: 4, 
        label: "Attendees", 
        condition: "AttendeeRegistration", 
        to: `/${showAbbr}/attendees`
      },
      {
        order: 5, 
        label: "Sponsors", 
        condition: "Conference", 
        to: `/${showAbbr}/sponsors`
      },
      {
        order: 6, 
        label: "Sessions", 
        condition: "Conference", 
        to: `/${showAbbr}/sessions`
      },
      {
        order: 7, 
        label: "Registration", 
        condition: "AttendeeRegistration", 
        to: `/${showAbbr}/registration`
      },
      {
        order: 8, 
        label: "My Account", 
        condition: "ApprovedExhibitor", 
        to: `${showAbbr}/invoice#Account`
      },
    ],
  },
  // Add other show-specific menus here as needed
};